<!-- 实名认证成功 -->
<template>
    <div class="msg-box">
        <img
            src="http://lkker-static.oss-cn-beijing.aliyuncs.com/designer-workbench/fadada/userSignCallback.png"
            alt=""
        />
        <p>{{ msg }}</p>
    </div>
</template>

<script>
import { webhookUserSign } from '@/api/common';
export default {
    name: 'UserSignCallback',
    data() {
        return {
            msg: '',
        };
    },
    mounted() {
        webhookUserSign(this.$route.query).then(([, error]) => {
            if (!error) {
                this.msg = '实名认证成功，可以返回洛客平台签署协议';
            } else {
                this.$message.error(error.msg);
            }
        });
    },
};
</script>

<style scoped lang="scss">
.msg-box {
    width: 280px;
    margin: 0 auto;
    img {
        width: 100%;
    }
    p {
        text-align: center;
        font-size: 14px;
        margin-top: 40px;
        padding-left: 10px;
    }
}
</style>
