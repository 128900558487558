<!-- 合同签约成功 -->
<template>
    <div class="msg-box">
        <img
            src="http://lkker-static.oss-cn-beijing.aliyuncs.com/designer-workbench/fadada/contractCallback.png"
            alt=""
        />
        <p>
            {{ msg }}
        </p>
    </div>
</template>

<script>
import { webhookContract } from '@/api/common';
export default {
    name: 'ContractCallback',
    data() {
        return {
            msg: '',
        };
    },
    mounted() {
        webhookContract(this.$route.query).then(([, error]) => {
            if (!error) {
                this.msg = '签约成功，可以返回洛客设计平台继续您的操作';
            } else {
                this.$message.error(error.msg);
            }
        });
    },
};
</script>

<style scoped lang="scss">
.msg-box {
    width: 380px;
    margin: 0 auto;
    img {
        width: 100%;
    }
    p {
        text-align: center;
        font-size: 14px;
        margin-top: 40px;
        padding-left: 10px;
    }
}
</style>
